import React from 'react'
import {Link} from 'gatsby'
import CookieConsent, { Cookies } from "react-cookie-consent";

const CookieConsentBanner = () => {
    return (
        <div>
            <CookieConsent
            	buttonText="OK!"
            	cookieName="NoroutineWebsiteCookiesConsent"
            	disableStyles={true}
            	buttonWrapperClasses="btn-box"
            	buttonClasses="default-btn"
            	containerClasses="website-cookies-consent"
                contentClasses="consent-content"
            	>

            	This website uses cookies to enhance your experience. Check our <Link to="/privacy">Privacy Disclaimer</Link> for details
            </CookieConsent>
        </div>
    )
}

export default CookieConsentBanner
