import React from 'react'
import {Link} from 'gatsby'
import logo from "../../assets/images/Noroutine_Logo.svg"
import aribaBadge from "../../assets/images/ariba/badge_180x55.jpg"
import footerMap from "../../assets/images/footer-map.png"

const Footer = () => {

    const currentYear = new Date().getFullYear();

    return (
        <footer className="footer-area bg-color">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-footer-widget">
                            <a href="/" className="logo">
                                <img src={logo} width="150" alt="logo" />
                            </a>
                            <p>Dependable IT Solutions</p>
                            <ul className="social-link">
                                <li>
                                    <Link to="https://www.facebook.com/noroutineme/" className="d-block" target="_blank" rel="noreferrer">
                                        <i className='bx bxl-facebook'></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="https://twitter.com/noroutine" className="d-block" target="_blank" rel="noreferrer">
                                        <i className='bx bxl-twitter'></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="https://www.instagram.com/noroutineme/" className="d-block" target="_blank" rel="noreferrer">
                                        <i className='bx bxl-instagram'></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="https://www.linkedin.com/company/noroutine/" className="d-block" target="_blank" rel="noreferrer">
                                        <i className='bx bxl-linkedin'></i>
                                    </Link>
                                </li>
                                <li>

                                </li>
                            </ul>
                        </div>
                        <div className="single-footer-widget">
                            <Link to="http://discovery.ariba.com/profile/AN01464905177" className="d-block" target="_blank" rel="noreferrer">
                                <img src={aribaBadge} alt="View Noroutine GmbH profile on Ariba Discovery" />
                            </Link>
                        </div>
                    </div>

<div className="col-lg-2 col-sm-6">
    <div className="single-footer-widget">
</div>
</div>

                    <div className="col-lg-2 col-sm-6">
                        <div className="pl-5 single-footer-widget">
                            <h3>Explore</h3>
                            
                            <ul className="footer-links-list">
                                <li>
                                    <Link to="/">
                                        Home
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/about-us">
                                        About Us
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/tech">
                                        IT Consulting
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/talent">
                                        Talent Scouting
                                    </Link>
                                </li>
{/*
                                <li>
                                    <Link to="/blog">
                                        Our Blog
                                    </Link>
                                </li>
*/}
                                <li>
                                    <Link to="/contact">
                                        Contact
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
{/*
                    <div className="col-lg-2 col-sm-6">

                        <div className="single-footer-widget">

                                <h3>Resources</h3>
                                */}

{/*
                            <ul className="footer-links-list">
                                                          */}  
{/*
                                <li>
                                    <Link to="/team">
                                        Our Team
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/services">
                                        Our Services
                                    </Link>
                                </li>
                                */}
                            {/*
                                <li>
                                    <Link to="/testimonials">
                                        Testimonials
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/services">
                                        SaaS Solutions
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/case-studies">
                                        Case Studies
                                    </Link>
                                </li>
                                */}
                            {/*
                            </ul>

                        </div>
                    </div>
                            */}
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-footer-widget">
                            <h3>Address</h3>

                            <ul className="footer-contact-info">
                                <li>
                                    <i className='bx bx-map'></i> 
                                    Noroutine GmbH <br />
                                    Berg-am-Laim Str. 64 <br />
                                    81673 Munich <br /> Germany
                                </li>
                                <li>
                                    <i className='bx bx-phone-call'></i>
                                    <a href="tel:+491631669687">+49 (0) 163 166 9687</a>
                                </li>
                                <li>
                                    <i className='bx bx-envelope'></i>
                                    <a href="mailto:info@noroutine.com">info@noroutine.com</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="footer-bottom-area">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-6">
                            <p>Copyright @ {currentYear} <strong>Noroutine GmbH</strong> All rights reserved</p>
                        </div>

                        <div className="col-lg-6 col-md-6">
                            <ul>
                                <li>
                                    <Link to="/apply">
                                        Apply
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/privacy">
                                        Privacy
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/terms">
                                        AGB (Terms)
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/imprint">
                                        Impressum (Imprint)
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="footer-map">
                <img src={footerMap} alt="footer-logo" />
            </div>
        </footer>
    );
}

export default Footer;