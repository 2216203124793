import React from 'react'
import { Helmet } from "react-helmet"
import { withPrefix } from "gatsby"

import bannerImg from '../../assets/images/banner-img1.png'
import fightingCovid from '../../assets/images/fighting-coronavirus/fighting-coronavirus-innovations.jpg'


const SEO = () => {
    return (
        <div>
            <Helmet>
                {/* Primary Meta Tags */}
                <title>noroutine | Your Cloud Partner</title>
                <meta name="title" content="noroutine | Your Cloud Partner" />
                <meta name="description" content="IT Consultancy and Talent Scouting" />

                {/* Open Graph / Facebook */}
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://noroutine.com/"></meta>
                <meta property="og:title" content="noroutine | Your Cloud Partner"></meta>
                <meta property="og:description" content="IT Consultancy and Talent Scouting"></meta>
                <meta property="og:image" content={"https://noroutine.com" + bannerImg}></meta>

                {/* Twitter */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:url" content="https://noroutine.com/" />
                <meta name="twitter:title" content="noroutine | Your Cloud Partner" />
                <meta name="twitter:description" content="IT Consultancy and Talent Scouting" />
                <meta name="twitter:image" content={"https://noroutine.com" + bannerImg} />

                <link rel="canonical" href="https://noroutine.com/"></link>
                <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png"></link>
                <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"></link>
                <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"></link>
                <link rel="manifest" href="/site.webmanifest"></link>
            </Helmet>
        </div>
    )
}

export default SEO
